/*card css starts from mobile to desktop*/
div.recog-card {
    padding: 0px;
    margin: 20px;
    box-shadow: 2px 2px 10px 4px rgb(191, 226, 242);
    text-align: center;
    position: relative;
    width: 75vw;
}

div.recog-card > div.recog-upper {
    height: 145vw;
    padding: 0vw 4vw 0vw 4vw;
    display: flex;
    align-items: center;
    font-size: 5vw;
    line-height: 1.6;
}

div.recog-card > div.recog-lower {
    min-height: fit-content;
    padding-bottom: 15%;
    text-align: left;
    position: relative;
}

div.recog-card > img.red-quote {
    position: absolute;
    z-index: 2;
    width: 25%;
    top: -0.7vw;
    right: -2.5%;
}

/*this ensures if red quote overlaps text, text is in front*/
div.textWhite {
    z-index: 3;
}

div.recog-card > div.recog-lower > img.recog-img {
    position: absolute;
    z-index: 2;
    width: 38%;
    left: 5px;
    transform: translateY(-30.4%);
    border-radius: 50%;
    border: 7px solid #fff;
}

/*custom underline name*/
div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
    font-size: 7vw;
    text-transform: uppercase;
    padding-bottom: 5px;
    position: relative;
    padding-top: 3vw;
    color: rgb(0, 0, 0);
    line-height: 1;
}

div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
    content: "";
    position: absolute;
    width: 16.5vw;
    height: 1px;
    bottom: 0px;
    border-bottom: 5px solid rgb(225, 27, 34);
}

div.recog-card > div.recog-lower > a.recog-name-link {
    text-decoration: none;
}

div.recog-card > div.recog-lower > div.recog-title {
    font-size: 4vw;
    font-weight: 600;
    padding-top: 5px;
    padding-right: 5px;
    line-height: 1.3;
}

div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
    margin-left: 34vw;
}

/*media queries: responsive as screen gets bigger (from mobile to desktop size)---------------------------------------------------------*/

@media screen and (min-width: 450px) {
    div.recog-card {
        width: 69vw;
    }

    div.recog-card > div.recog-upper {
        height: 70vw;
        font-size: 3vw;
        line-height: 1.6;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 4vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 9.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 2.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 32.5vw;
    }
}

@media screen and (min-width: 720px) {
    div.recog-card > div.recog-upper {
        height: 50vw;
        font-size: 1.8vw;
    }
    
    div.recog-card, div.recog-upper, div.recog-lower {
        width: 36vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 2.2vw;
        padding-top: 1.2vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 5.3vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 1.3vw;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 16.5vw;

    }
}

@media screen and (min-width: 1080px) {
    div.recog-card > div.recog-upper {
        height: 26vw;
        font-size: 1.05vw;
    }
    
    div.recog-card, div.recog-upper, div.recog-lower {
        width: 24vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 1.5vw;
        padding-top: 0.7vw;
    }
    
    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 3.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 0.9vw;
        padding-top: 3px;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 11vw;
    }
}
