.footerBackground {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.footerText {
  font-size: 1.8vw;
}

.buttonSoc {
  padding: 0;
  margin: 0 auto;
  background-color: rgb(58, 89, 152);
  border: none;
  border-radius: 4px;
  outline: none !important;
}

.btnSocName {
  display: none;
}

.btnSocImg {
  margin-right: 5%;
  margin-bottom: 3.3%;
  width: 4.5rem;
}

@media screen and (min-width: 768px) {
  .footerBackground {
    padding-bottom: 0.5%;
    text-align: center;
  }

  .buttonSoc {
    padding: 0.75rem 2rem;
    margin: 1.5rem;
    white-space: nowrap;
  }

  .btnSocName {
    display: inline-block;
  }

  .btnSocImg {
    width: 8rem;
  }
}
