@font-face {
  font-family: 'Graphik';
  src: url(./assets/fonts/graphik/Graphik-Regular-App.ttf);
}

@font-face {
  font-family: 'Graphik';
  font-weight: 1000;
  src: local('Graphik'), url(./assets/fonts/graphik/Graphik-Bold-App.ttf) format('trueType');
}

body {
  padding: 0;
  margin: 0;
  font-family: Graphik, Arial, sans-serif !important;
}

html {
  overflow-y: scroll;
}

.headingUnderline {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px !important;
  position: relative;
  color: rgb(39, 54, 121);
}

.headingUnderline:before {
  content: "";
  position: absolute;
  width: 148px;
  height: 1px;
  bottom: -12px;
  border-bottom: 5px solid rgb(225, 27, 34);
}

.allCaps {
  text-transform: uppercase;
}

.backgroundPrimary1 {
  background: rgb(191, 226, 242);
}

.backgroundPrimary2 {
  background: rgb(225, 27, 34);
}

.backgroundPrimary3 {
  background: rgb(39, 54, 121);
}

.backgroundWhite {
  background: rgb(255, 255, 255);
}

.textPrimary2 {
  color: rgb(225, 27, 34);
}

.textPrimary3 {
  color: rgb(39, 54, 121);
}

.textAccent3 {
  color: rgb(128, 129, 132);
}

.textWhite {
  color: rgb(255, 255, 255);
}

.PrivateTabIndicator-colorPrimary-3 {
  background-color: inherit !important;
}

.din-condensed-font {
  font-family: din-condensed, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.map {
  position: relative;
  width: 100% !important;
  margin: 0 auto !important;
  padding-top: 20px;
}

a.carousel-control-next, a.carousel-control-prev {
  opacity: 1; /*make arrows red and not opaque*/
  transition: opacity 0.8s ease; /*slide transition kinda match designer's, but no need to be exact..*/
}

@media screen and (min-width: 992px) {
  
  .cityMap1 {
    position: absolute;
    /* top: -48px; */
    top: -11px;
    left: 20px;
    width: 40%;
  }

  .cityMap2 {
    display: none;
  }

}

@media screen and (min-width: 1200px) {
  
  .cityMap1 {
    width: 48%;
  }

}

@media screen and (min-width: 1400px) {
  
  .cityMap1 {
    width: 46%;
    left: 20px;
  }

}

@media screen and (min-width: 1550px) {
  
  .cityMap1 {
    width: 42%;
  }

}

@media screen and (min-width: 1650px) {
  
  .cityMap1 {
    width: 40%;
    left: 80px;
  }

}

@media screen and (min-width: 1800px) {
  
  .cityMap1 {
    width: 36%;
    left: 150px;
  }

}

@media screen and (min-width: 2050px) {
  
  .cityMap1 {
    width: 33%;
    left: 200px;
  }

}

@media screen and (max-width: 991px) {
  
  .cityMap1 {
    display: none;
  }

  .cityMap2 {
    margin: 0 auto;
    background-color: rgb(191, 226, 242);
    width: 80%;
  }

}

/* wrapper class to keep all content equal distance from edges */
.wrapper {
  padding-left: 12vw !important;
  padding-right: 12vw !important;
  width: 100%;
}

/* adds margin on top through all pages */
.top-margin {
  margin-top: 5vw !important;
}

.left-pad {
  padding-left: 25px;
}

.sub-wrap {
  width: 100%;
  margin: 0 auto;
}

/* Here we have the wrapper expand on larger screens */
@media screen and (min-width: 1024px) {
  
  .wrapper {
    padding: 0 ;
    width: 95%; 
  }

  .left-pad {
    padding-left: 5px;
  }

  .sub-wrap {
    width: 100%;
    padding: 10px 50px;
  }

}
