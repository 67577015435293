/* BASE MOBILE STYLES  <769 */
  .profileCardT {
    position: relative;
    height: 430px;
    width: 100%;
    margin: 0 auto 15px;
  }

  .upperRectangleT {
    height: 60%;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    max-width: 312px;
  }

  .cardText1T {
    width: 100%;
    height: 30%;
  }

  .quoteT {
    font-family: Graphik, Arial, Sans-Serif; 
    font-size: 1rem; 
    font-weight: 400;
    line-height: 140%;
  }
  
  .cardPhotoT {
    width: 200px;
    position: absolute;
    top: 44%;
    left: calc(50% - 100px);
  }

  .lowerRectangleT {
    height: 40%;
    margin: 0 auto;
  }
    
  .cardText2T {
    position: absolute;
    bottom: 14px;
    text-align: center;
    width: 100%;
    padding-bottom: 0%;
    font-size: 22px;
    font-weight: 600;
  }
  
  .cardText3T {
    position: absolute;
    right: 1%;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding-bottom: 0;
    font-size: 1rem;
    font-weight: 1000;
  }
  
  /* Tablet and Landscape STYLES 769-1024 */
  @media screen and (min-width:768px){
    
    .profileCardT {
      height: 475px;
    }

    .upperRectangleT {
      width: 100%;
    }

  }

  /* desktop STYLES >1024 */
  @media screen and (min-width:1024px){

    .profileCardT {
      height: 450px;
    }

    .quoteT { 
      font-size: 1.1vw; 
    }

    .upperRectangleT {
      max-width: 500px;
    }

  }