/* NAVBAR STYLES */
.can-navbar-btnholder{
    display: flex;
    align-items: center;
    align-content: center;
  }
.can-logo{
    position: absolute;
    height: 70px;
}
.can-navbar{
    text-transform: "capitalize";
    font-size: 18px;
    font-weight: 600;
    min-width: 240;
    flex-grow: 1;
    margin: 0;
    background-color: rgb(39, 54, 121);;
}

.navbar-icon{
    position: absolute;
    right:20px;
    top:20px;
}

.can-menu-dropdown{
    background-image: linear-gradient(to bottom,
    rgb(21, 30, 70) 1px,  
    rgb(39, 54, 121) 15px, 
    rgb(39, 54, 121));
}

.can-nav-btns{
    list-style-type: none;
    color: white !important;
    font-family: Graphik, Arial, Sans-Serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 0 1.8rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }



.height-sm{
    height: 50px;
    min-height:50px;
  }

.height-sm:hover{
        background-color: #E11B22;
    }

.height-lg{
    height: 70px;
    min-height:70px;
  }

.c-links{
    text-decoration: none; 
    color: white !important;
    justify-content: center;
  }
.c-links:hover{
    text-decoration: none;   
    color: white !important;
}

.c-links:active{
    text-decoration: none;   
    color: white !important;
    background-color: none;
}

.lineheight-sm{
    line-height: 3.5;
  }

.lineheight-lg{
    line-height: 5;
  }

.nav-btns-large{
    display: inline-flex;
    justify-content: center;
    margin:auto;
}

.nav-margin{
    padding-left: 7%;
}
