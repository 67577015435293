@import url(https://p.typekit.net/p.css?s=1&k=swu3faj&ht=tk&f=10875&a=46993767&app=typekit&e=css);
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * din-condensed:
 *   - http://typekit.com/eulas/0000000000000000000146b3
 *
 * © 2009-2021 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2021-07-22 21:02:19 UTC"}*/

@font-face {
font-family:"din-condensed";
src:url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

.tk-din-condensed { font-family: "din-condensed",sans-serif; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerImg {
  width: 100%;
}

.headerTxt {
  text-transform: uppercase;
  color: rgb(39, 54, 121);
  font-weight: 1000;
  font-size: 2.5vw;
  left: 2.8%;
  top: 10%;
  margin-top: 1.5em;
}

#text-up{
  padding-left: 0;
}

.cannav {
  background-color: rgb(39, 54, 121);
  height: 70px;
}

.links {
  color: white !important;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}

.brand {
  margin-top: 10px;
  width: 20%;
}

nav {
  padding: 0;
}

.full {
  padding: 0 !important;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding-top: 0 !important;
}

.navMargin {
  margin-left: 7%;
}

#taglineCss{
  padding-left: 0.4%;
  font-size: 1.8vh;
  color: white;
  background-color: rgba(39, 54, 121, 0.5);
  font-weight: bold;
  left: 2.8%
}

/*using an id to override bootstrap's formatting*/
#tagline-overlay {
  display: flex;
  align-items: flex-end; /*this keeps the tagline on the bottom of the image*/
  padding-bottom: 0%;
}

.image-overlay {
  background: linear-gradient(
      to right,
      #cbebff 0%,
      #e5f3f94f 50%,
      #ffffff00 70%,
      #ffffff00 100%
    ),
    no-repeat center/cover;
}

.navbar-toggler {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1200px) {

  .headerTxt {
    font-size: 1.5em;
  }

}

@media screen and (max-width: 1099px) {

  .headerTxt {
    font-size: 1.3em;
  }

}

@media screen and (max-width: 958px) {

  .headerTxt {
    font-size: 1.2em;
    margin-top: 0.5em;
  }

}

@media screen and (max-width: 650px) {

  .headerTxt {
    margin-top: 2em;
  }

}

@media screen and (max-width: 320px) {

  .headerTxt {
    margin-top: 0em;
  }

}

/* NAVBAR STYLES */
.can-navbar-btnholder{
    display: flex;
    align-items: center;
    align-content: center;
  }
.can-logo{
    position: absolute;
    height: 70px;
}
.can-navbar{
    text-transform: "capitalize";
    font-size: 18px;
    font-weight: 600;
    min-width: 240;
    flex-grow: 1;
    margin: 0;
    background-color: rgb(39, 54, 121);;
}

.navbar-icon{
    position: absolute;
    right:20px;
    top:20px;
}

.can-menu-dropdown{
    background-image: linear-gradient(to bottom,
    rgb(21, 30, 70) 1px,  
    rgb(39, 54, 121) 15px, 
    rgb(39, 54, 121));
}

.can-nav-btns{
    list-style-type: none;
    color: white !important;
    font-family: Graphik, Arial, Sans-Serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 0 1.8rem !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }



.height-sm{
    height: 50px;
    min-height:50px;
  }

.height-sm:hover{
        background-color: #E11B22;
    }

.height-lg{
    height: 70px;
    min-height:70px;
  }

.c-links{
    text-decoration: none; 
    color: white !important;
    justify-content: center;
  }
.c-links:hover{
    text-decoration: none;   
    color: white !important;
}

.c-links:active{
    text-decoration: none;   
    color: white !important;
    background-color: none;
}

.lineheight-sm{
    line-height: 3.5;
  }

.lineheight-lg{
    line-height: 5;
  }

.nav-btns-large{
    display: inline-flex;
    justify-content: center;
    margin:auto;
}

.nav-margin{
    padding-left: 7%;
}

.whatIsAppship h3 {
  font-size: 22px; 
  font-weight: 600;
  line-height: 30px;
}

.picBox {
  margin-top: 1vw !important;
}

.bullets {
  text-align: center;
}

@media screen and (min-width: 992px) {

  .whatIsAppship {
    margin-top: 100px;
    margin-bottom: 20px;

  }

  .missionBottomMargin {
    margin-bottom: 225px !important;
  }

}

@media screen and (max-width: 991px) {
  
  .whatIsAppship {
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .missionBottomMargin {
    margin-bottom: 50px !important;
  }

}

.borderless {
  border: none !important;
}

.lightfootCaption {
  font-size: 0.7em;
  margin: 0;
  text-align: right;
}

.lightfootSpeaking {
  margin-top: 5%;
  padding: 0 !important;
}

.red {
  background-color: rgb(229, 80, 85);
  font-size: 14px;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .lightfootSpeaking {
    margin-top: 5%;
  }
}
.missionBold {
  margin-top: 0;
  font-weight: 700;
}

.mission p {
  margin-top: 35px;
  line-height: 24px;
}
div.rec {
  padding-bottom: 100px;
}

div.rec h4 {
  padding-top: 50px;
  margin-bottom: 50px;
}

div.rec > div.row > div.carousel { /*specify path for Recognition carousel only*/
  width: 100%;
}

div.rec > div.row > div.carousel > div.carousel-inner { /*specify path for Recognition carousel only*/
  width: 90%;
  margin: 0 auto;
}

div.recog-carou > ol.carousel-indicators {
  bottom: -80px;
}

div.recog-carou > ol.carousel-indicators > li {
  background-image: url(/static/media/StarEmpty_.474a5daf.svg); /*if folder changes, need to update this!*/
  height: 22px;
  width: 23px;
  background-size: 25px;
  background-repeat: no-repeat;
  margin: 0;
}

div.recog-carou > ol.carousel-indicators > li.active {
  background-image: url(/static/media/StarFill.2d257ab3.svg); /*if folder changes, need to update this!*/
}

img.recog-next-arrow, img.recog-prev-arrow {
  width: 5vw;
}

/*As the screen gets bigger: from mobile to desktop ----------------------------------------------------------------->*/

@media screen and (min-width: 720px) { /*adjusting the arrows' size*/
  img.recog-next-arrow, img.recog-prev-arrow {
    width: 2.5vw;
  }
}

@media screen and (min-width: 1500px) { /*adjusting the indicators' size*/
  div.recog-carou > ol.carousel-indicators > li {
    height: 29px;
    width: 29px;
    background-size: 32px;
  }
}
/*card css starts from mobile to desktop*/
div.recog-card {
    padding: 0px;
    margin: 20px;
    box-shadow: 2px 2px 10px 4px rgb(191, 226, 242);
    text-align: center;
    position: relative;
    width: 75vw;
}

div.recog-card > div.recog-upper {
    height: 145vw;
    padding: 0vw 4vw 0vw 4vw;
    display: flex;
    align-items: center;
    font-size: 5vw;
    line-height: 1.6;
}

div.recog-card > div.recog-lower {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    padding-bottom: 15%;
    text-align: left;
    position: relative;
}

div.recog-card > img.red-quote {
    position: absolute;
    z-index: 2;
    width: 25%;
    top: -0.7vw;
    right: -2.5%;
}

/*this ensures if red quote overlaps text, text is in front*/
div.textWhite {
    z-index: 3;
}

div.recog-card > div.recog-lower > img.recog-img {
    position: absolute;
    z-index: 2;
    width: 38%;
    left: 5px;
    transform: translateY(-30.4%);
    border-radius: 50%;
    border: 7px solid #fff;
}

/*custom underline name*/
div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
    font-size: 7vw;
    text-transform: uppercase;
    padding-bottom: 5px;
    position: relative;
    padding-top: 3vw;
    color: rgb(0, 0, 0);
    line-height: 1;
}

div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
    content: "";
    position: absolute;
    width: 16.5vw;
    height: 1px;
    bottom: 0px;
    border-bottom: 5px solid rgb(225, 27, 34);
}

div.recog-card > div.recog-lower > a.recog-name-link {
    text-decoration: none;
}

div.recog-card > div.recog-lower > div.recog-title {
    font-size: 4vw;
    font-weight: 600;
    padding-top: 5px;
    padding-right: 5px;
    line-height: 1.3;
}

div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
    margin-left: 34vw;
}

/*media queries: responsive as screen gets bigger (from mobile to desktop size)---------------------------------------------------------*/

@media screen and (min-width: 450px) {
    div.recog-card {
        width: 69vw;
    }

    div.recog-card > div.recog-upper {
        height: 70vw;
        font-size: 3vw;
        line-height: 1.6;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 4vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 9.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 2.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 32.5vw;
    }
}

@media screen and (min-width: 720px) {
    div.recog-card > div.recog-upper {
        height: 50vw;
        font-size: 1.8vw;
    }
    
    div.recog-card, div.recog-upper, div.recog-lower {
        width: 36vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 2.2vw;
        padding-top: 1.2vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 5.3vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 1.3vw;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 16.5vw;

    }
}

@media screen and (min-width: 1080px) {
    div.recog-card > div.recog-upper {
        height: 26vw;
        font-size: 1.05vw;
    }
    
    div.recog-card, div.recog-upper, div.recog-lower {
        width: 24vw;
    }

    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name {
        font-size: 1.5vw;
        padding-top: 0.7vw;
    }
    
    div.recog-card > div.recog-lower > a.recog-name-link > div.recog-name:before {
        width: 3.5vw;
    }

    div.recog-card > div.recog-lower > div.recog-title {
        font-size: 0.9vw;
        padding-top: 3px;
    }

    div.recog-card > div.recog-lower > div.recog-title, div.recog-name {
        margin-left: 11vw;
    }
}

.involvedBackground {
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
}

.involvedText {
  font-weight: 1000;
  font-size: 30px;
  margin-bottom: 50px;
}

.buttonInv {
  width: 12rem;
  padding: 1.75rem 0;
  margin: 1%;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 800;
  outline: none !important;
}

.home-img-container {
  float: left;
  display: flex;
  margin: 0 2rem;
}

@media screen and (max-width: 772px) {
  .buttonInv {
    width: 8rem;
    padding: 1rem 0;
    margin-top: 1rem;
    font-size: 12px;
  }

  .involvedText {
    font-weight: 1000;
    font-size: 18px;
    margin-bottom: 50px;
  }
}
.logoInd {
  object-fit: scale-down;
  width: 66%;
  height: 100%;
}

.logoBlock {
  margin: 1% 0% 1% 0%;
  text-align: center;
}

.mem {
  margin: 100px 0 100px 0;
}

.mem h4 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 35px;
}

.containerLogo {
  margin-bottom: 5%;
}

.memberUnderline {
  padding-bottom: 5px;
  position: relative;
  color: rgb(39, 54, 121);
}

.memberUnderline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -12px;
  border-bottom: 5px solid rgb(225, 27, 34);
}

/*styling starting from mobile to desktop, to make it responsive - Mobile/Smallest screens Less than 720p*/
div.carousel-bkgrd {
    margin-bottom: 20vw;
    box-shadow: 0px 4px 9px #ccc;
}

.logo-col-css, .empty-logo-col-css {
    margin: 1% 0% 1% 0%;
    text-align: center;
}

img.logo-css {
    object-fit: scale-down;
}

img.logo-css, .empty-logo-col-css {
    width: 22vw;
    height: 11vw;
}

img.next-arrow-css, img.prev-arrow-css {
    width: 5vw;
    position: absolute;
}

img.next-arrow-css {
    right: -7vw;
}

img.prev-arrow-css {
    left: -7vw;
}

.logo-row-css {
    justify-content: center;
}

div.carousel-bkgrd > ol.carousel-indicators {
    bottom: -80px;
}

div.carousel-bkgrd > ol.carousel-indicators > li {
    background-image: url(/static/media/StarEmpty_.474a5daf.svg);
    height: 25px;
    width: 23px;
    background-size: 25px;
    background-repeat: no-repeat;
    margin: 0;
}

div.carousel-bkgrd > ol.carousel-indicators > li.active {
    background-image: url(/static/media/StarFill.2d257ab3.svg);
}

div.carousel-bkgrd > div.carousel-inner {
    padding: 15px;
}

/*media queries: as screen gets bigger-----------------------------------------------------------------------------*/
@media screen and (min-width: 420px) { /*special adjustment to bottom of carousel - where the indicators are*/
    div.carousel-bkgrd {
        margin-bottom: 15vw;
    }
}

/*Tablet screens 720p + */
@media screen and (min-width: 720px) {
    div.carousel-bkgrd {
        margin-bottom: 10vw;
    }

    div.carousel-bkgrd > div.carousel-inner {
        padding: 25px;
    }

    img.logo-css, .empty-logo-col-css {
        width: 14vw;
        height: 6vw;
    }

    img.next-arrow-css, img.prev-arrow-css {
        width: 2.5vw;
    }

    img.next-arrow-css {
        right: -4vw;
    }
    
    img.prev-arrow-css {
        left: -4vw;
    }
}

/*Standard screens 1080p + */
@media screen and (min-width: 1080px) {
    div.carousel-bkgrd {
        margin-bottom: 8vw;
    }

    div.carousel-bkgrd > div.carousel-inner {
        padding: 50px;
    }

    img.logo-css, .empty-logo-col-css {
        width: 11vw;
        height: 7vw;
    }

    div.carousel-bkgrd > ol.carousel-indicators > li {
        height: 3vw;
        width: 2.5vw;
        background-size: 2.8vw;
    }

    div.carousel-bkgrd > ol.carousel-indicators {
        bottom: -7vw;
    }
}

/*special, for indicators */
@media screen and (min-width: 1400px) {
    div.carousel-bkgrd > ol.carousel-indicators > li {    
        height: 4vw;
    }
}

@media screen and (min-width: 2100px) {
    div.carousel-bkgrd > ol.carousel-indicators {
        bottom: -6vw;
    }
}
/*STYLES SHARED BETWEEN MOBILE AND DESKTOP*/

.padding {
  background-color: rgb(191, 226, 242);
}

.regFontBC { 
  padding-right: 35px !important;
}

.rowText {
  margin-left: 37% !important;
}

h6 {
  font-weight: 1000 !important;
}

.boldText {
  margin-bottom: 10px;
  font-weight: 700;
}

.stats {
  text-align: center;
  padding-top: 40px;
}

.city {
  border: 0 !important;
}

.textBC {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.textSmallDetail{
  font-size: small; 
  font-weight: 375
}

/*DESKTOP STYLES*/
@media screen and (min-width: 992px) {

  .padding {
    margin-left: 14% !important;
    margin-right: 10% !important;
    padding-top: 5% !important;
    padding-bottom: 6% !important;
    margin-bottom: 12% !important;
    padding-left: 0 !important;
    box-shadow: 3px 3px 5px rgb(252, 221, 221);
  }

  .number {
    color: rgb(39, 54, 121);
    font-size: 60px;
    font-weight: 900;
    line-height: 30px;
  }

  .statText {
    font-weight: 700;
    font-size: 3vh;
  }

}

/*MOBILE STYLES*/
@media screen and (max-width: 991px) {

  .regFontBC { 
    font-size: 1.25rem;
    padding-bottom: 20px;
  }

  .rowText {
    width: 90%;
    margin: 0 auto !important;
  }

  .boldText {
    font-size: 1.2rem;
  }

  .padding {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
  }

  .number {
    color: rgb(39, 54, 121);
    font-weight: 1000;
    font-size: 2rem;
  }

  .textBC {
    font-size: 1rem;
  }

  .textSmallDetail{
    font-size: small; 
    font-weight: 375
  }

}
.emp-margin {
  margin-top: 4vh !important;
}

.benefits-heading {
  margin-bottom: 35px !important;
}

.benefits-sub-heading {
  font-size: 2.7vh;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 30px;
}

.circle {
  display: table-cell;
  height: 14vw;
  border-top-right-radius: 50% 100%;
  border-bottom-right-radius: 50% 100%;
  border-top-left-radius: 50% 100%;
  border-bottom-left-radius: 50% 100%;
}

.circle-text {
  font-size: 1.2vw;
}

.source-text {
  font-family: "Graphik", Arial, Sans-Serif;
  font-size: 1.5vh;
}

.source-text:hover {
  text-decoration: none;
}

.special-text {
  font-family: "Graphik", Arial, Sans-Serif;
  font-size: 3vh;
  line-height: 4vh;
}

.circle-text span {
  font-weight: 900;
}

.text-container {
  margin: 0 10%;
}

.circ-container {
  margin-right: 30px;
}

.circleBox {
  padding: 0% !important;
  margin-left: 2vw !important;
}

.empBenefitsBox {
  width: 80.5vw !important;
}

@media screen and (max-width: 450px) {
 
  .circleBox {
    padding: 4% !important;
    margin-left: 18px !important;
  }
  .circle {
    display: table-cell;
    height: 9rem !important;
    padding-left: 10px;
     }
  .circle-text {
    font-size: 0.8rem;
    white-space: initial;
  }
}

@media screen and (max-width: 720px) {
  .circle {
    display: table-cell;
    height: 10rem !important;
   
  }
  .circle-text {
    font-size: 1rem;
    white-space: initial;
  }
}

.heading {
  font-weight: bolder;
  padding: 2% 1% 0.3% 2%;
}

.start-heading{
  margin-top: 10vh;
}

.text {
  font-weight: bolder;
  font-size: 4vh;
}

.styleRow {
  padding: 4% 5% 4% 0%;
}

.sp-container {
  margin-bottom: 3vh;
}

.pdf-img {
  height: 100%;
}

@media screen and (max-width: 450px) {

  .sp-container {
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 3%;
  }

  .start-heading{
    font-size: 2rem;
  }

}

.upperRectangle {
  height: 60%;
  margin: 0 auto;
}

.lowerRectangle {
  height: 40%;
  margin: 0 auto;
}

.cardText1 {
  width: 80%;
  height: 30%;
  position: absolute;
  top: 5%;
  left: 10%;
  text-align: center;
}

.quote {
  font-size: 16px;
}

.cardText2 {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 1%;
  margin-bottom: 5%;
  font-size: 22px; 
  font-weight: 600;
  line-height: 30px;
}

.cardText3 {
  position: absolute;
  right: 1%;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding-bottom: 0;
  font-size: 1rem;
  font-weight: 1000;
}

.cardPhoto {
  width: 50%;
  position: absolute;
  top: 40%;
  left: 25%;
}

.profileCard {
  position: relative;
  height: 28vw;
}

@media screen and (max-width:1515px){
  .cardText2 {
    font-size: 1.7vw;
    margin-bottom: 4%;
  }

}

@media screen and (max-width:767px){
  .profileCard {
    height:85vw;
    margin-bottom: 7%;
  }

  .quote {
    font-size: 2.8vw !important;
  }

  .cardPhoto {
    top: 25vw;
  }

  .upperRectangle {
    height: 62%;
  }

  .cardText1 {
    height: 20%;
    top: 5%;
    width: 70%;
    left: 15%;
  }

  .cardText2 {
    font-size: 3.5vw;
    margin-bottom: 4%;
  }

}

@media screen and (max-width:707px){
  .profileCard {
    height:88vw;
    margin-bottom: 7%;
  }

  .cardPhoto {
    top: 30vw;
  }

  .cardText1 {
    top: 7%;
  }
}

@media screen and (max-width:682px){
  .profileCard {
    height:90vw;
  }
}

@media screen and (max-width:624px){
  .profileCard {
    height:92vw;
  }
}

@media screen and (max-width:570px){
  .profileCard {
    height:95vw;
  }
}

@media screen and (max-width:540px){
  .profileCard {
    height:95vw;
  }

  .cardPhoto {
    top: 33vw;
  }

  .upperRectangle {
    height: 58%;
  }
}

@media screen and (max-width:500px){
  .profileCard {
    height:95vw;
  }
}

@media screen and (max-width:450px){
  .profileCard {
    height:85vw;
    margin-bottom: 7%;
  }

  .quote {
    font-size: .9rem !important;
  }

  .cardPhoto {
    top: 23vw;
  }

  .upperRectangle {
    height: 62%;
  }

  .cardText1 {
    height: 20%;
    top: 6%;
    width: 70%;
    left: 15%;
  }

  .cardText2 {
    font-size: 3.5vw;
    margin-bottom: 4%;
  }
}

@media screen and (max-width:430px){
  .profileCard {
    height:90vw;
  }
}

@media screen and (max-width:414px){
  .profileCard {
    height:82vw;
    margin-bottom: 7%;
  }

  .quote {
    font-size: 1rem;
  }

  .cardPhoto {
    top: 22vw;
  }

  .upperRectangle {
    height: 56%;
  }

  .cardText1 {
    height: 20%;
    top: 3%;
    width: 80%;
    left: 10%;
  }

  .cardText2 {
    font-size: 3.5vw;
    margin-bottom: 4%;
  }

  .cardText3{
    font-size: .9rem;
  }
}

@media screen and (max-width:400px){
  .profileCard {
    height:83vw;
  }
  .cardPhoto {
    top: 24vw;
  }
}

@media screen and (max-width:367px){
  .profileCard {
    height:88vw;
  }

  .cardText1 {
    top: 5%;
  }
}

@media screen and (max-width:330px){
  .profileCard {
    height:90vw;
  }

  .cardText1 {
    top: 6%;
  }

  .cardPhoto {
    top: 26vw;
  }
}

@media screen and (max-width:310px){
  .profileCard {
    height:95vw;
  }

  .cardText1 {
    top: 7%;
  }
}

@media screen and (max-width:280px){
  .profileCard {
    height:91vw;
  }
  .cardText1 {
    top: 9%;
  }
  .cardPhoto {
    top: 28vw;
  }
}





.mt-container {
    margin-top: 6vh;
}

.mt-heading {
    margin-top: 8vh;
}

@media screen and (max-width:450px){

    .mt-container {
        width: 100% !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        margin-top: 3%;
    }

    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

}

@media screen and (max-width:1024px){
    
    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */
    
}

.progcard-container {
  height: 33vw;
  max-height: 33vw;
  overflow: hidden;
  width: 92%;
  margin-bottom: 8vh;
}

.progcard-container img {
  max-width: 60%;
  max-height: 6vh;
  object-fit: contain;
  object-position: 0 0;
  margin-top: 8%;
  margin-left: 5%;
}

.progcard-footer {
  background-color: rgb(39, 54, 121) !important;
  text-align: center;
  color: rgb(255, 255, 255);
}

.progcard-footer a {
  color: rgb(255, 255, 255);
}

.progcard-footer a:hover {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.progcard-body {
  margin: 0 5%;
  padding: 0rem !important;
}

.progcard-prog-info-heading {
  font-weight: bold;
}

.progcard-recruiting-badge {
  position: absolute;
  top: 0;
  right: 5%;
  background-color: rgb(225, 27, 34);
  text-align: center;
  width: 25%;
  line-height: 2rem;
  color: rgb(255, 255, 255);
  letter-spacing: 0.03rem;
  font-size: 1.3vh;
  font-weight: bold;
}

.progcard-desc {
  padding-top: 3%;
  font-size: 1vw;
}

.progcard-prog-info {
  font-size: 1rem;
  line-height: 1.9rem;
}

@media screen and (max-width: 1615px) {
  .progcard-recruiting-badge {
    font-size: 1vh;
  }
}

@media screen and (max-width: 1540px) {
  .progcard-desc {
    font-size: 1vw;
  }

  .progcard-prog-info {
    font-size: 1vw;
  }
}

@media screen and (max-width: 1415px) {
  .progcard-recruiting-badge {
    font-size: 0.8vh;
  }
  .progcard-container {
    height: 39vw;
    max-height: 39vw;
  }
}

@media screen and (max-width: 1070px) {
  .progcard-recruiting-badge {
    font-size: 0.5vh;
  }

  .progcard-desc {
    font-size: 0.9vw;
  }

  .progcard-prog-info {
    font-size: 0.9vw;
  }

  .progcard-container {
    height: 37vw;
    max-height: 37vw;
  }
  .progcard-footer a {
    font-size: 1.2vw;
  }
}

@media screen and (max-width: 943px) {
  .progcard-recruiting-badge {
    font-size: 0.5vh;
  }

  .progcard-desc {
    font-size: 0.9vw;
  }

  .progcard-prog-info {
    font-size: 0.9vw;
  }

  .progcard-container {
    height: 42vw;
    max-height: 42vw;
  }
}

@media screen and (max-width: 767px) {
  .progcard-container {
    height: 63vw;
    max-height: 63vw;
    width: 100%;
  }

  .progcard-recruiting-badge {
    font-size: 1.3vh;
  }
  .progcard-desc {
    font-size: 2.2vw;
  }

  .progcard-prog-info {
    font-size: 2.2vw;
  }

  .progcard-footer a {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 707px) {
}

@media screen and (max-width: 682px) {
  .progcard-container {
    height: 67vw;
    max-height: 67vw;
  }
}

@media screen and (max-width: 624px) {
  .progcard-container {
    height: 70vw;
    max-height: 70vw;
  }
}

@media screen and (max-width: 570px) {
  .progcard-recruiting-badge {
    font-size: 1vh;
  }
}

@media screen and (max-width: 540px) {
  .progcard-container {
    height: 73vw;
    max-height: 73vw;
  }
}

@media screen and (max-width: 500px) {
  .progcard-container {
    height: 76vw;
    max-height: 76vw;
  }
}

@media screen and (max-width: 450px) {
  .progcard-container {
    height: 84vw;
    max-height: 84vw;
    width: 90%;
  }
  .progcard-desc {
    font-size: 3vw;
  }
  .progcard-prog-info {
    font-size: 3vw;
    line-height: 5.1vw;
  }

  .progcard-recruiting-badge {
    font-size: 2.5vw;
  }
  .progcard-footer {
    padding: 0.25rem 1.25rem !important;
  }
  .progcard-footer a {
    font-size: 3.5vw;
  }
}

@media screen and (max-width: 414px) {
  .progcard-desc {
    font-size: 3vw;
  }
  .progcard-prog-info {
    font-size: 3vw;
    line-height: 5.1vw;
  }

  .progcard-recruiting-badge {
    font-size: 2.5vw;
  }
  .progcard-footer {
    padding: 0.25rem 1.25rem !important;
  }
  .progcard-footer a {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 400px) {
  .progcard-container {
    height: 90vw;
    max-height: 90vw;
  }
}

@media screen and (max-width: 310px) {
  .progcard-container {
    height: 94vw;
    max-height: 94vw;
  }
}

@media screen and (max-width: 280px) {
  .progcard-container {
    height: 98vw;
    max-height: 98vw;
  }

  .progcard-desc {
    font-size: 3vw;
  }
  .progcard-prog-info {
    font-size: 3vw;
    line-height: 5.1vw;
  }

  .progcard-recruiting-badge {
    font-size: 2.5vw;
  }
  .progcard-footer {
    padding: 0.25rem 1.25rem !important;
  }
  .progcard-footer a {
    font-size: 3.5vw;
  }
}

.eo-container-main {
    background: rgb(191, 226, 242);
    padding-top: 4% !important;
}

.eo-container {
    margin-top: 4%;
}

.eo-heading {
    padding-top: 3%;
    color: rgb(39, 54, 121);
}

@media screen and (max-width:1024px) {
    
    .eo-heading {
        font-size: 2.5vw;
        margin-bottom: 4%;
        padding-top: 9%;
    }
    
    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

}

@media screen and (max-width: 767px) {

    .eo-container {
      width: 95% !important;
    }
    
    .eo-heading {
        font-size: 3.5vw;
        margin-bottom: 6%;
    }
    
    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

}

@media screen and (max-width:450px) {
    
    .eo-heading {
        font-size: 3.5vw;
        margin-bottom: 8%;
    }

    .eo-container {
        width: 100% !important;
        padding-right: 0px !important;
        padding-left: 0px !important;
        margin-top: 3%;
    }
    
    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

}
/* BASE MOBILE STYLES  <769 */
  .profileCardT {
    position: relative;
    height: 430px;
    width: 100%;
    margin: 0 auto 15px;
  }

  .upperRectangleT {
    height: 60%;
    margin: 0 auto;
    padding: 15px;
    text-align: center;
    max-width: 312px;
  }

  .cardText1T {
    width: 100%;
    height: 30%;
  }

  .quoteT {
    font-family: Graphik, Arial, Sans-Serif; 
    font-size: 1rem; 
    font-weight: 400;
    line-height: 140%;
  }
  
  .cardPhotoT {
    width: 200px;
    position: absolute;
    top: 44%;
    left: calc(50% - 100px);
  }

  .lowerRectangleT {
    height: 40%;
    margin: 0 auto;
  }
    
  .cardText2T {
    position: absolute;
    bottom: 14px;
    text-align: center;
    width: 100%;
    padding-bottom: 0%;
    font-size: 22px;
    font-weight: 600;
  }
  
  .cardText3T {
    position: absolute;
    right: 1%;
    bottom: 0;
    text-align: center;
    width: 100%;
    padding-bottom: 0;
    font-size: 1rem;
    font-weight: 1000;
  }
  
  /* Tablet and Landscape STYLES 769-1024 */
  @media screen and (min-width:768px){
    
    .profileCardT {
      height: 475px;
    }

    .upperRectangleT {
      width: 100%;
    }

  }

  /* desktop STYLES >1024 */
  @media screen and (min-width:1024px){

    .profileCardT {
      height: 450px;
    }

    .quoteT { 
      font-size: 1.1vw; 
    }

    .upperRectangleT {
      max-width: 500px;
    }

  }
.apd-container {
    margin-top: 2%;
    margin-bottom: 4%;
}

.apd-para-one {
    width: 71vw;
}

.apd-para-heading {
    width: 100%;
    font-size: 26px; 
    font-weight: 700;
}

.quote {
    font-size: 1.1vw;
}

@media screen and (max-width:1367px) {

    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

    .apd-para-one {
        /* font-size: 1.1vw; */
    }

}

@media screen and (max-width:767px) {

    .apd-container {
        width: 100% !important;
        margin-top: 3%;
    }

    /* .headerTxt {
        font-size: 2.5vw;
        width: 50%;
        left: 5%;
        top: 10%;
    } */

    .apd-para-one {
        font-size: 1rem;
        width: 90%;
    }

    .apd-para-heading {
        font-size: 1rem;
        width: 90%;
    }
    
}
.default-line-background{
  background-color: rgb(191, 226, 242);
}

.ep-line-separator {
  width: 73.8%;
  margin-left: 12vw;
  height: 5px;
  background-color:rgb(39, 54, 121);
}

.ep-container-main {
  background: rgb(191, 226, 242);
  padding-top: 3%;
}

.ep-container {
  margin-top: 1%;
}

.ep-heading {
  margin-bottom: 4%;
}

.ep-text {
  font-weight: 700;
  font-size: 26px; 
}

.ep-para {
  padding-bottom: 1%;
  width: 75%;
}

@media screen and (max-width: 1024px) {

  .ep-container-main {
    padding-top: 2%;
  }

  .ep-heading {
    font-size: 2.5vw;
    margin-bottom: 4%;
  }

  /* .headerTxt {
    font-size: 2.5vw;
    width: 50%;
    left: 5%;
    top: 10%;
  } */

  .ep-text {
    font-size: 3.5vw;
  }

  .ep-para {
    width: 80%;
    font-size: 1.1vw;

  }

}

@media screen and (max-width: 767px) {

  .ep-container {
    width: 90% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 3%;
  }

  .ep-heading {
    font-size: 3.5vw;
    margin-bottom: 8%;
  }

  /* .headerTxt {
    font-size: 2.5vw;
    width: 50%;
    left: 5%;
    top: 10%;
  } */

  .ep-para {
    font-size: 2.5vw;
    width: 90%;
  }

  .ep-text {
    font-size: 4.5vw;
  }

  .ep-line-separator {
    display: none;
  }

}

@media screen and (max-width: 450px) {
  
  .ep-heading {
    font-size: 3.5vw;
    margin-left: 5.5%;
    margin-bottom: 8%;
  }

  .ep-container {
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-top: 3%;
  }

  /* .headerTxt {
    font-size: 1rem;
    width: 50%;
    left: 5%;
    top: 0;
  } */

  .ep-para {
    font-size: 3vw;
    margin-left: 5.5%;
  }

  .ep-text {
    font-size: 4.5vw;
    margin-left: 5.5%;
  }
  
  .ep-line-separator {
    display: none;
  }
  
}

.wnc-container {
  width: 98vw !important;
  margin-bottom: 7vw !important;
}

.wnc-desc {
  margin-bottom: 10% !important;
}

.wnc-heading {
  margin-bottom: 3%;
  clear: none;
  font-size: 26px; 
  font-weight: 700;
}

.wnc-button {
  background-color: rgb(225, 27, 34) !important;
  box-shadow: 4px 4px 4px rgb(221, 141, 143);
  font-size: 18px !important; 
  font-weight: 800 !important; 
  width: 20vw;
}

.wnc-iframe {
  height: 50vh;
  border: none;
  width: 98%;
}

.wnc-video-heading {
  float: right;
  margin-right: 2%;
}

#video-transcript-btn {
  font-size: 0.8vw;
  font-weight: bold;
  color: rgb(39, 54, 121);
}

#video-transcript-btn:focus {
  outline: none;
  box-shadow: none;
}

#video-transcript-btn:active {
  outline: none;
  box-shadow: none;
}

#video-transcript-btn:hover {
  text-decoration: none;
}

.wnc-video-container {
  position: relative;
  width: 100%;
  margin: 0.5vw 0 0 -0.5vw;
}

.modal-wrapper {
  width: 179%;
  height: 134%;
  z-index: 100;
  margin: 4em auto;
  position: absolute;
  top: -9%;
  left: -19%;
}

.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgb(39, 54, 121) !important ;
  padding: 2%;
  width: 100%;
  height: 100%;
  color: white;
  top: -10%;
  left: 10%;
}

.close {
  float: right;
  margin-right: 2%;
  color: white !important;
  font-size: 2.6vw !important ;
  font-weight: 200 !important;
}

.close:link {
  background: rgb(39, 54, 121) !important;
}

.close:active {
  outline: none;
}

.trans-head {
  margin-left: 2%;
  margin-bottom: 1.5%;
  color: white !important;
}

.trans-text {
  margin-left: 2%;
  margin-top: 2%;
}

@media screen and (max-width: 1367px) {
  
  .wnc-iframe {
    height: 30vh;
  }
  
  .wnc-desc {
    /* font-size: 1.1vw; */
  }

}

@media screen and (max-width: 767px) {
  
  .wnc-container {
    width: 90% !important;
    margin-left: 5% !important;
    margin-bottom: 10% !important;
    padding: 0 !important;
  }

  .wnc-desc {
    font-size: 1rem;
    margin-bottom: 3% !important;
  }
  
  .wnc-heading {
    font-size: 1rem;
  }
  
  .wnc-iframe {
    height: 37vh;
  }
  
  .wnc-video-heading {
    font-size: 2vw;
  }

  .wnc-button {
    width: 100%;
    font-size: 2.9vw !important;
    box-shadow: 2px 3px 3px rgb(221, 141, 143);
  }
  
  .wnc-video-container {
    margin: 0 0 0 1%;
  }

}
.eventCard {
  padding: 0% !important;
}

.eventCardOuterCol {
  padding-left: 0.7vw !important;
  padding-right: 0vw !important;
}

.eventHolder {
  margin-bottom: 6vh;
}

.upcomingEventDescription {
  white-space: pre-line;
  padding: 0 !important;
}

.titleRow {
  margin-left: 5px !important;
}

.upcomingEventTitle {
  width: 65%;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 30px;
  padding-top: 15px;
  padding-left: 20px;
  height: 100px;
}

.dateBubble {
  width: 32%;
  display: inline-block;
  height: 90px;  
  width: 90px;
  border-radius: 50%;
  font-size: 14px;
  padding-top: 2px;
  margin-bottom: -.375em;
}

.titleDiv {
  display: inline-block;
}

.dateBubble div {
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 2em;
}

.monthStyle {
  margin: 5px 0 -5px;
}

.tbdStyle {
  margin-top: 20px;
}

.topDiv {
  display: inline-flex;
}

.headingEventsPage {
  margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {

  /* .headingEventsPage{
    font-weight: 900;
  } */

  /* .upcomingEventTitle {
    font-size: 2rem;
    font-weight: 600;
    line-height: 30px;
    padding-top: 15px;
    padding-left: 20px;
    height: 100px;
    text-transform: capitalize;
  } */
  
}

@media screen and (max-width: 379px) {

  .upcomingEventTitle {
    font-size: 20px !important;
    line-height: 20px;
    padding-top: 5px;
  }

  .dateBubble {
    width: 32%;
    display: inline-block;
    height: 70px;  
    width: 70px;
  }

  .dateBubble div {
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 20px;
  }

}

.imageDiv {
  text-align: center;
}

.pastEventImg {
  width: 23.6vw;
  height: 29vh;
  object-fit: contain;
}

.eventHolder {
  margin-top: 20px;
  margin-bottom: 6vh;
}

.eventTitle {
  margin-top: 10px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.eventDescription {
  white-space: pre-line; /*for adding newline to description*/
}

@media screen and (max-width: 1200px) {

  /* .pastEventImg {
    height: 45vw;
  } */
  
}

@media screen and (max-width: 800px) {
  
  .pastEventImg {
    width: 100%;
  }
  
}

.featuredNews {
  background-color: #E11B22;
  padding: 20px;
  margin-bottom: 6vh;
  margin-top: 24px;
}

.featuredNewsCard {
  margin: 10px;
  padding: 20px;
  background-color: #bfe2f2;
}

.featuredNewsImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.featuredNewsHeader, .featuredNewsHeader a, .featuredNewsHeader a:hover {
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #273679;
  height: 150px;
  text-decoration: none;
}

.featuredNewsMainHeader {
  color: white;
  text-transform: uppercase;
  margin-bottom: .75em;
  margin-top: .625em;
  font-size: 26px; 
  font-weight: 700;
}

.featuredNewsSource {
  padding-top: 30px;
  color: #273679;
  text-align: right;
}

.featuredArticles {
  width: 95%;
  padding-left: 5%;
  margin: 0 auto;
}

.newsStoryCard {
  margin: 10px;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
}

.featuredNewsHeader {
  font-size: 22px;
  font-weight: 600;
  line-height: 40px;
  padding-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #273679;
  height: 150px;
  text-decoration: none;
}

.newsStoryArticles {
  width: 95%;
  padding-left: 5%;
  margin: 0 auto;
}

.newsStoryMainHeader {
  color: white;
  text-transform: uppercase;
  margin-bottom: .75em;
  margin-top: .625em;
  font-size: 26px; 
  font-weight: 700;
}

.newsStorySource {
  color: #273679;
  font-style: italic;
}

@media screen and (max-width: 969px) {
  
  .featuredNewsSource {
    margin-top: 20px;
  }

  .featuredArticles {
    width: 110% !important;
    padding-left: 0%;
    margin-right: 0;
  }

  .featuredNewsHeader, .featuredNewsHeader a, .featuredNewsHeader a:hover {
    height: auto;
    margin-bottom: 0;
  }

  .featuredNewsSource {
    padding-top: 0;
  }

  .featuredNewsMainHeader {
    margin-left: calc(50% - 100px);
    font-weight: 600;
    font-size: 1.5rem;
    color: white;
  }

  .newsStory {
    width: 100%
  }

  .newsStoryArticles {
    width: 110% !important;
  } 

  .newsStoryCard {
    padding: 0;
  }

  .newsStoryMainHeader {
    font-weight: 600;
    font-size: 1.5rem;
  }

  .newsStorySource {
    padding-top: 0;
    margin-top: 20px;
  }
}

.articleCopy {
  font-family: Graphik, Arial, Sans-Serif;
}

.articleHeader {
  font-size: 18px; 
  font-weight: 600;
}

.articleInfo {
  font-style: italic;
  font-size: 12px;
}

.chicagoFlag {
  margin-left: auto;
  margin-right: auto; 
  align-content: center;
  margin-bottom: 50px;
}

.dollarSign {
  height: 24px;
  width: auto;
}

.expandButton {
  color:#273679;
  background-color: white;
  background:none;
  border:none;
  padding: 0;
  cursor: pointer;
  font-weight: 900;
  outline: none !important; /*remove the outline on the year archive buttons when clicked on*/
}

.expandButton:hover {
  color: rgb(191,226,242)
}

.fullWidthUnderline {
  padding-bottom: 5px;
  position: relative;
  font-weight: 700;
  font-size: 26px;
}

.fullWidthUnderline:before {
  content: "";
  position: absolute;
  width: 75vw;
  height: 4px;
  bottom: 0;
  border-bottom: 5px solid rgb(225, 27, 34);
}

.readMoreLink, .readMoreLink:hover, .readMoreLink:visited {
  text-decoration: none;
  font-family: Graphik, Arial, Sans-Serif;
  color: #212529;
}

.spacerImage {
  text-align: center;
}

.yearlyArchiveContainer {
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 24px;
}

.archivedArticle {
  margin: 20px 0px 20px 0px;
}

@media screen and (max-width: 969px) {
  
  .articleCopy {
    display: none;
  }

  .spacerImage {
    display: none;
  }

}
.footerBackground {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
}

.footerText {
  font-size: 1.8vw;
}

.buttonSoc {
  padding: 0;
  margin: 0 auto;
  background-color: rgb(58, 89, 152);
  border: none;
  border-radius: 4px;
  outline: none !important;
}

.btnSocName {
  display: none;
}

.btnSocImg {
  margin-right: 5%;
  margin-bottom: 3.3%;
  width: 4.5rem;
}

@media screen and (min-width: 768px) {
  .footerBackground {
    padding-bottom: 0.5%;
    text-align: center;
  }

  .buttonSoc {
    padding: 0.75rem 2rem;
    margin: 1.5rem;
    white-space: nowrap;
  }

  .btnSocName {
    display: inline-block;
  }

  .btnSocImg {
    width: 8rem;
  }
}

@font-face {
  font-family: 'Graphik';
  src: url(/static/media/Graphik-Regular-App.b5315802.ttf);
}

@font-face {
  font-family: 'Graphik';
  font-weight: 1000;
  src: local('Graphik'), url(/static/media/Graphik-Bold-App.b76c8cc4.ttf) format('trueType');
}

body {
  padding: 0;
  margin: 0;
  font-family: Graphik, Arial, sans-serif !important;
}

html {
  overflow-y: scroll;
}

.headingUnderline {
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 5px !important;
  position: relative;
  color: rgb(39, 54, 121);
}

.headingUnderline:before {
  content: "";
  position: absolute;
  width: 148px;
  height: 1px;
  bottom: -12px;
  border-bottom: 5px solid rgb(225, 27, 34);
}

.allCaps {
  text-transform: uppercase;
}

.backgroundPrimary1 {
  background: rgb(191, 226, 242);
}

.backgroundPrimary2 {
  background: rgb(225, 27, 34);
}

.backgroundPrimary3 {
  background: rgb(39, 54, 121);
}

.backgroundWhite {
  background: rgb(255, 255, 255);
}

.textPrimary2 {
  color: rgb(225, 27, 34);
}

.textPrimary3 {
  color: rgb(39, 54, 121);
}

.textAccent3 {
  color: rgb(128, 129, 132);
}

.textWhite {
  color: rgb(255, 255, 255);
}

.PrivateTabIndicator-colorPrimary-3 {
  background-color: inherit !important;
}

.din-condensed-font {
  font-family: din-condensed, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.map {
  position: relative;
  width: 100% !important;
  margin: 0 auto !important;
  padding-top: 20px;
}

a.carousel-control-next, a.carousel-control-prev {
  opacity: 1; /*make arrows red and not opaque*/
  transition: opacity 0.8s ease; /*slide transition kinda match designer's, but no need to be exact..*/
}

@media screen and (min-width: 992px) {
  
  .cityMap1 {
    position: absolute;
    /* top: -48px; */
    top: -11px;
    left: 20px;
    width: 40%;
  }

  .cityMap2 {
    display: none;
  }

}

@media screen and (min-width: 1200px) {
  
  .cityMap1 {
    width: 48%;
  }

}

@media screen and (min-width: 1400px) {
  
  .cityMap1 {
    width: 46%;
    left: 20px;
  }

}

@media screen and (min-width: 1550px) {
  
  .cityMap1 {
    width: 42%;
  }

}

@media screen and (min-width: 1650px) {
  
  .cityMap1 {
    width: 40%;
    left: 80px;
  }

}

@media screen and (min-width: 1800px) {
  
  .cityMap1 {
    width: 36%;
    left: 150px;
  }

}

@media screen and (min-width: 2050px) {
  
  .cityMap1 {
    width: 33%;
    left: 200px;
  }

}

@media screen and (max-width: 991px) {
  
  .cityMap1 {
    display: none;
  }

  .cityMap2 {
    margin: 0 auto;
    background-color: rgb(191, 226, 242);
    width: 80%;
  }

}

/* wrapper class to keep all content equal distance from edges */
.wrapper {
  padding-left: 12vw !important;
  padding-right: 12vw !important;
  width: 100%;
}

/* adds margin on top through all pages */
.top-margin {
  margin-top: 5vw !important;
}

.left-pad {
  padding-left: 25px;
}

.sub-wrap {
  width: 100%;
  margin: 0 auto;
}

/* Here we have the wrapper expand on larger screens */
@media screen and (min-width: 1024px) {
  
  .wrapper {
    padding: 0 ;
    width: 95%; 
  }

  .left-pad {
    padding-left: 5px;
  }

  .sub-wrap {
    width: 100%;
    padding: 10px 50px;
  }

}

